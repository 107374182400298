.App {
  text-align: center;
}

.container {
  background-color: black;
  width: 800px;
  height: 800px;
}

p {
  font-size: 1.5rem;
  color: white;
  box-shadow: 0 0 5 5 lightgray
}